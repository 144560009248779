import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { UtilService } from './shared/util.service';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		MatIconModule,
		provideFirebaseApp(() => initializeApp(environment.firebaseKeys)),
		provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
	],
	providers: [
		{ provide: FIREBASE_OPTIONS, useValue: environment.firebaseKeys },
		UtilService
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
