import { commons } from "./environment.commons";

export const environment = {
    production: false,
    firebaseKeys: {
        apiKey: "AIzaSyA8pameJ4hr7sAQ3yOYbezTizVfmrhSQUk",
        authDomain: "pe-gcp-customercare-02.firebaseapp.com",
        databaseURL: "https://pe-gcp-customercare-02-default-rtdb.firebaseio.com",
        projectId: "pe-gcp-customercare-02",
        storageBucket: "pe-gcp-customercare-02.appspot.com",
        messagingSenderId: "52415902569",
        appId: "1:52415902569:web:074df110c5b005912743f8",
        measurementId: "G-PEHB8D4PPB"
    },
    API_RETURNS: 'https://order-returns-dev.cc.cloudintercorpretail.pe',
    API_FILES: 'https://files-dev.cc.cloudintercorpretail.pe',
    API_STORE: 'https://store-prd.cc.cloudintercorpretail.pe',
    API_TRANSACTIONS: 'https://transaction-dev.cc.cloudintercorpretail.pe',
    API_INCIDENCE: 'https://tracking-incidence-dev.cc.cloudintercorpretail.pe/v2',
    API_CHANGES_RETURNS: 'https://order-returns-dev.cc.cloudintercorpretail.pe',
    API_RULES: 'https://returns-rules-dev.cc.cloudintercorpretail.pe',
    API_ACCOUNT: "https://api.cc-irdigital.pe/dev/aria/aria-api-accounts",
    API_REX: "https://rex-requests-dev.cc.cloudintercorpretail.pe",
    uploadBucket: "pe-indigitalxp-rex-files-dev",
    commons
}
