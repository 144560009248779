import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{ path: ':tenantId/photo-attachment', loadChildren: () => import('./photo-attachment/photo-attachment.module').then(m => m.PhotoAttachmentModule) },
	{ path: ':tenantId/:storeCode/store-registration-form', loadChildren: () => import('./store-registration-form/store-registration-form.module').then(m => m.StoreRegistrationFormModule) }
];

const routingConfiguration: ExtraOptions = {
	scrollPositionRestoration: 'enabled'
};

@NgModule({
	imports: [RouterModule.forRoot(routes, routingConfiguration)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
