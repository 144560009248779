import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { TICKET_TYPE } from "src/environments/environment.commons";

@Injectable()
export class UtilService {

	recieveTenantId = new Subject<string>();
	recieveCodeStore = new Subject<string>();
	recieveTicketType = new Subject<TICKET_TYPE>();

	setTenantId(tenantId: string) {
		this.recieveTenantId.next(tenantId);
	}

	setStoreCode(storeCode: string) {
		this.recieveCodeStore.next(storeCode);
	}

	setTicketType(ticketTypeId: TICKET_TYPE) {
		this.recieveTicketType.next(ticketTypeId);
	}
}
